/**
 * Stores build information. The string literals are replaces at compile time by `set_version.js`.
 */
export const buildInfo = {
    versionNumber: "0.1.0",
    versionName: "nightly 2024-03-09",
    versionChannel: "nightly",
    buildDate: "2024-03-09T00:05:07.324Z",
    commitHash: "60d7b14c59760b2a399b6965ad4a9acff359fbb2",
};
